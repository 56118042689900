import React from 'react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle';
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../node_modules/bootstrap-icons/font/bootstrap-icons.min.css';
// datatable css
import "../node_modules/datatables.net-dt/css/jquery.dataTables.min.css";
// datatable
import '../node_modules/datatables.net-dt/css/jquery.dataTables.min.css';
import '../node_modules/datatables.net-dt/js/dataTables.dataTables.min.js';
import '../node_modules/react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import './index.scss';
import {render} from 'react-dom'


render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
