import React, { useState, useEffect } from "react";
import { Card, Button, Table, Form, Modal, Col, Row } from "react-bootstrap";
import JSZip from "jszip";
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from "datatables.net-dt";
import { postToAPI, getFromAPI, putToAPI, deleteFromAPI } from "../Utils/utils";
import { toast, ToastContainer } from "react-toastify";

export default function Wards({ activeTab }) {
    // Modal
    const [showWardModal, setShowWardModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [wardData, setwardData] = useState();
    const [zonesData, setZonesData] = useState();
    const [supervisorData, setSupervisorData] = useState();

    // Form validation
    const [validated, setValidated] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [EditWardID, setEditWardID] = useState();
    const [DeleteWardID, setDeleteWardID] = useState();

    const [wardInputData, setwardInputData] = useState({
        name: "",
        zoneId: 0,
          
    })

    const FetchWardData = () => {
        getFromAPI('ward/').then(data => {
            setwardData(data.ward);
        }).catch(error => {
            console.error('Error fetching Zone data:', error);
        });
    }

    const FetchAllZones = () => {
        getFromAPI('zone/').then(data => {
            setZonesData(data);
        }).catch(error => {
            console.error('Error fetching zones:', error);
        });
    }

    const fetchSupervisorData = () => {
        getFromAPI('supervisor/').then(data => {
            setSupervisorData(data.supervisors);
        }).catch(error => {
            console.error('Error fetching supervisors:', error);
        });
    }

    const handleAddWard = async (e) => {
        e.preventDefault(); // Prevent page reload
        const form = e.currentTarget;
        const formIsValid = form.checkValidity();
    
        if (!formIsValid) {
            e.stopPropagation();
            setValidated(true);
            // toast.error('Ward Name and Zone are required.');
        } else {
            setValidated(true);

           
    
            if (wardInputData.name.trim() === "") {
                toast.error('A ward with this name already exists.');
                return;
            }
    
            try {
                let Response;
                if (isEdit) {
                    Response = await putToAPI(`ward/${EditWardID}/`, wardInputData);
                    if (Response.status) {
                        toast.success('Ward Successfully updated');
                        
                    } else {
                        toast.error('Failed to update Ward');
                    }
                } else {
                    const isDuplicate = wardData.some(ward => {
                        return (
                            (ward.name.toLowerCase() === wardInputData.name.toLowerCase()) &&
                            (!isEdit || ward.id !== EditWardID)
                        );
                    });
            
                    if (isDuplicate) {
                        toast.error('A ward with this name already exists.');
                        return;
                    }
                    Response = await postToAPI("ward/", wardInputData);
                    if (Response.status) {
                        toast.success('Ward Added Successfully');
                    } else {
                        toast.error('Failed to add Ward');
                    }
                }
                
                UpdateWardDataTable();
                handleCloseWardModal();
                FetchAllZones();
            } catch (error) {
                console.error('Error:', error);
                toast.error('An unexpected error occurred');
            }
        }
    };
    

    const handleEditWard = (title, ward_id) => {
        getFromAPI("ward/").then(data => {
    
            if (Array.isArray(data.ward)) {
                // Convert ward_id to number to match the fetched data's id type
                const ward_data = data.ward.find(ward => ward.id === Number(ward_id));
    
                if (ward_data) {
                    setwardInputData({
                        name: ward_data.name || '',
                        zoneId: ward_data.zone.id || 0,
                    });
                    setEditWardID(ward_id);
                    handleOpenWardModal(title);
                    setIsEdit(true);
                } else {
                    console.error('Ward not found in data:', ward_id);
                }
            } else {
                console.error('Unexpected data structure:', data);
            }
        }).catch(error => {
            console.error('Error fetching ward:', error);
        });
    };
    
    
    
    

    const handleOpenWardModal = (title) => {
        setModalTitle(title);
        setShowWardModal(true);
    };

    const handleDeleteData = (id) => {
        // Set the selected id and show the modal
        setDeleteWardID(id);
        setShowDeleteModal(true);
    };

    const handleDeleteWard = async () => {
        // Check if this ward is assigned to any supervisor
        const isAssignedWard = supervisorData.some(supervisor => {
            // Ensure supervisor.ward is an array before using .some
            const wards = Array.isArray(supervisor.ward) ? supervisor.ward : [];
            return wards.some(ward => parseInt(ward.id) === parseInt(DeleteWardID));
        });
    
        if (isAssignedWard) {
            toast.error('This ward is currently assigned to a supervisor. Please remove the ward from the supervisor’s assignment before attempting to delete it.');
        } else {
            try {
                const response = await deleteFromAPI(`ward/${DeleteWardID}/`);
                if (response.status) {
                    UpdateWardDataTable();
                    toast.success('Ward successfully deleted');
                    handleCloseDeleteModal();
                } else {
                    toast.error('Failed to delete ward');
                }
            } catch (error) {
                console.error('Error:', error);
                toast.error('An unexpected error occurred');
            }
        }
    };
    

    const handleCloseDeleteModal = () => {
        setDeleteWardID(null);
        setShowDeleteModal(false);
    }

    const handleCloseWardModal = () => {
        setIsEdit(false);
        setValidated(false);
        setShowWardModal(false);
        setEditWardID(null);
        setwardInputData({
            name: "",
            zoneId: 0,
        });
    }

    // Datatable
    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (wardData && !$.fn.dataTable.isDataTable("#wardDataTable")) {
            $("#wardDataTable").DataTable({
                dom: 'Bflrtip',
                buttons: [
                    {
                        extend: 'excel',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" style="fill: currentcolor;"></path></svg> Export to Excel'
                    },
                    {
                        extend: 'pdf',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" style="fill: currentcolor;"></path></svg> Export to Pdf'
                    },
                    {
                        extend: 'print',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M19 8H5C3.9 8 3 8.9 3 10V16H7V20H17V16H21V10C21 8.9 20.1 8 19 8M17 18H7V14H17V18M19 12H5V10H19V12Z" style="fill: currentcolor;"></path></svg> Print'
                    }
                ],
                data: wardData.map((data, i) => [
                    i + 1,
                    data.name,
                    data.zone.name,
                    data.id,
                    // data.supervisor  // No need for this column
                ]),
                columns: [
                    { title: "No." },
                    { title: "Ward Name" },
                    { title: "Zone" },
                    // { title: "Assigned Supervisor" },  // No need for this column
                    {
                        title: "Action",
                        render: function (data, type, row) {
                            return `
                                <div class="d-flex">
                                    <button class="btn btn-soft-primary table-btn me-2"><i class="bi bi-pencil-square edit-ward" data-ward-id="${row[3]}"></i></button>
                                    <button class="btn btn-soft-danger table-btn"><i class="bi bi-trash3 delete-ward" data-ward-id="${row[3]}"></i></button>
                                </div>
                            `;
                        }
                    },
                ],
                autoWidth: false,
                columnDefs: [
                    {
                        targets: -1,
                        orderable: false,
                    },
                ],
                select: true,
                language: {
                    search: "",
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: "«",
                        next: "»",
                    },
                },
            });
            // Event delegation to handle delete action
            document.querySelector('#wardDataTable tbody').addEventListener('click', function (event) {
                if (event.target && event.target.classList.contains('edit-ward')) {
                    const ward_id = event.target.getAttribute('data-ward-id');
                    handleEditWard("Edit Ward", ward_id);
                }
                if (event.target && event.target.classList.contains('delete-ward')) {
                    const ward_id = event.target.getAttribute('data-ward-id');
                    handleDeleteData(ward_id);
                }
            });

           
        }
    }, [wardData]);

    const UpdateWardDataTable = () => {
        const PODT = $('#wardDataTable').DataTable();
        getFromAPI("ward/")
            .then(response => {
                setwardData(response.ward);
                let DTData = response.ward.map((data, i) => [
                    i + 1,
                    data.name,
                    data.zone.name,
                    data.id,
                ]);
            PODT.clear().rows.add(DTData).draw();
            })
            .catch(error => {
            console.error('Error:', error);
            });
    }

    useEffect(() => {
        if (activeTab == "wards")
        {
            FetchWardData();
            FetchAllZones();
            fetchSupervisorData();
        }
    }, [activeTab])

    return (
        <>
            <div className="d-flex align-items-center flex-wrap gap-2 justify-content-between my-3">
                <Card.Title className="mb-0">Wards</Card.Title>
                <Button variant="primary" onClick={() => handleOpenWardModal("Add Ward")}>
                    <i className="bi bi-plus-square me-2"></i> Add Ward
                </Button>
            </div>

       
            <Table responsive bordered id="wardDataTable">
                <thead className="table-light"></thead>
            </Table>

            {/* Ward Modal */}
            <Modal centered size="lg" show={showWardModal} onHide={handleCloseWardModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={(e) => handleAddWard(e)}>
                    <Modal.Body className="pb-0">
                        <Row>
                            <Col lg={12}>
                                <Form.Group className="form-space">
                                    <Form.Label>Ward Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter ward name..." name="name" value={wardInputData.name} onChange={(e)=>{setwardInputData({...wardInputData, name:e.target.value})}} required isInvalid={validated && !wardInputData.name} />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter ward name.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                    
                            <Col lg={12}>
                                <Form.Group className="form-space">
                                    <Form.Label>Zone</Form.Label>
                                    <Form.Select as="select" name="zone" value={wardInputData.zoneId} onChange={(e)=>{setwardInputData({...wardInputData, zoneId:e.target.value})}} required isInvalid={validated && !wardInputData.zoneId} >
                                        <option value="">Select Zone</option>
                                        {zonesData && zonesData.map((zone) => (
                                            <option key={zone.id} value={zone.id}>
                                                {zone.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select zone.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className="mt-1">
                        <Button type="submit" variant="primary">Save</Button>
                        <Button variant="light" onClick={handleCloseWardModal}>Close</Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            {/* Delete Modal */}
            <Modal centered show={showDeleteModal} onHide={handleCloseDeleteModal}>
                <Modal.Body className="text-center">
                    <div className="avatar avatar-xxxl bg-border-soft-danger rounded-circle text-danger mx-auto ">
                        <i className="bi bi-trash"></i>
                    </div>
                    <h4 className="fw-semibold mt-4">Are you sure?</h4>
                    <p className="text-muted fs-18">Do you really want to delete this record?</p>
                    <div className="d-flex gap-3">
                        <Button variant="light w-50" onClick={handleCloseDeleteModal}>
                            Cancel
                        </Button>
                        <Button variant="danger w-50" onClick={handleDeleteWard}>
                            Delete
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
