import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap';
import JSZip from "jszip";
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from "datatables.net-dt";
import 'datatables.net-dt/css/jquery.dataTables.css';
import { getFromAPI } from '../Utils/utils'

export default function VehicleDetails({ vehicleDetailsData , onLoadingComplete }) {
  // State to hold updated vehicle details with ward and zone info
  const [updatedVehicleDetails, setUpdatedVehicleDetails] = useState(vehicleDetailsData);

  // Call updateWardNames and set the updated data into state
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Initialize DataTable after data is updated
        if (!$.fn.dataTable.isDataTable("#vehicledetailsdatatable")) {
          $("#vehicledetailsdatatable").DataTable({
            dom: 'Bfrtip',
            buttons: [
              {
                extend: 'excel',
                className: 'btn btn-export btn-md btn-lightdark',
                text: 'Export to Excel'
              },
              {
                extend: 'pdf',
                className: 'btn btn-export btn-md btn-lightdark',
                text: 'Export to PDF'
              },
              {
                extend: 'print',
                className: 'btn btn-export btn-md btn-lightdark',
                text: 'Print'
              }
            ],
            autoWidth: false,
            columnDefs: [{ targets: -1, orderable: false }],
            language: {
              search: "",
              searchPlaceholder: "Search...",
              paginate: { previous: "«", next: "»" }
            }
          });
        }
      } 
      catch (error) 
      {
        console.error('Error updating ward names:', error);
      } 
      finally 
      {
        onLoadingComplete(); // Notify parent that loading is complete
      }
    };

    fetchData(); // Call the async function to fetch and update the data
  }, [vehicleDetailsData]);

  return (
    <>
      <Table responsive bordered className="mb-0 table-nowrap" id="vehicledetailsdatatable">
        <thead className="table-light">
          <tr>
            <th>ID</th>
            <th>Date Assigned</th>
            <th>Destination</th>
            <th>QR Code</th>
            <th>Ward</th>
            <th>Zone</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {/* Render updated vehicle details */}
          {updatedVehicleDetails.length > 0 ? (
            updatedVehicleDetails.map((data, i) => (
              <tr key={i}>
                <td>{data.id}</td>
                <td>{data.date_assigned}</td>
                <td>{data.destination}</td>
                <td>{data.qrCode}</td>
                <td>{data.ward}</td>
                <td>{data.zone}</td>
                <td>
                  <span className={`badge badge-soft-${data.done ? "success" : "danger"}`}>
                    {data.done ? "Done" : "Pending"}
                  </span>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" className="text-center">Loading...</td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
}
